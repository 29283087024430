import styled, { keyframes } from 'styled-components';
import Button from 'react-bootstrap/Button';

const animationX = keyframes`
  0% {
    transform: translateX(100%);
    opacity:0;
    z-index: 0;
  }

  100% {

    transform: translateX(0%);
    opacity:1;
  }
`;

const animatioY = keyframes`
  0% {
    transform: translateY(100%);
    opacity:0;

  }

  100% {

    transform: translateY(0%);
    opacity:1;
  }
`;
export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`;

export const ContainerImage = styled.div`
  width: 70%;
  display: flex;
  justify-content: flex-end;
  height: 100vh;
  background-color: ${({ theme: { colors } }) => colors.primary}!important;
  @media (max-width: 962px) {
    width: 50%;
  }

  @media (max-width: 768px) {
    display: none;
  }
`;

export const Image = styled.img`
  max-height: 100%;
  max-width: 100%;
  object-fit: contain;
  animation-name: ${animationX};
  animation-duration: 2s;
`;

export const ContainerForm = styled.div`
  background: #fff;
  display: flex;
  z-index: 100;
  justify-content: center;
  align-items: center;
  width: 30%;
  box-shadow: -3px 3px 5px 0px rgba(0, 0, 0, 0.16);
  height: 100vh;
  position: relative;

  @media (max-width: 962px) {
    width: 50%;
  }

  @media (max-width: 768px) {
    width: 100%;
  }

  .favorite-page {
    width: 100%;
    position: absolute;
    top: 1rem;
    display: flex;
    justify-content: center;
    z-index: 1000;

    .content-favorite {
      background-color: #f3f4f6;
      padding: 0.5rem 1rem;
      border-radius: 1rem;
      display: flex;
      align-items: center;
      gap: 0.5rem;
      box-shadow: 0 0.25rem 0.375rem rgba(0, 0, 0, 0.1);
      transition: all 0.3s ease;

      &:hover {
        background-color: #e5e7eb;
      }

      .icon {
        font-size: 1.25rem;
        color: #6b7280;
      }

      .text {
        color: #374151;
        font-size: 0.875rem;
      }

      .shortcut {
        font-size: 0.75rem;
        color: #9ca3af;
        margin-left: 0.25rem;
      }
    }
  }
`;

export const Form = styled.form`
  padding: 20px;
  border-radius: 5px;
  width: 100%;
  animation-name: ${animatioY};
  animation-duration: 1.5s;
  .logo {
    display: block;
    width: 100px;
    height: 100px;
    margin: 0 auto;
    margin-bottom: 20px;
  }
  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const ButtonSubmit = styled(Button)`
  display: flex;
  align-items: center !important;
  justify-content: center !important;
  background-color: ${({ theme: { colors } }) => colors.primary}!important;
  height: 40px;
  width: 100%;
  color: #fff !important;
  margin-top: 0.8rem !important;
  border-color: transparent !important;
  &:focus:not(.focus-visible) {
    box-shadow: none;
  }
  &:focus-visible {
    box-shadow: 0 0 0 3px ${({ theme: { colors } }) => colors.secondary} !important;
  }

  span {
    color: #fff !important;
    font-size: 16px !important;
    letter-spacing: 0.006rem;
    font-weight: 500 !important;
  }

  &:hover {
    opacity: 0.9;
  }
`;

export const StepButton = styled.button<{ isSuccess: boolean }>`
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-14%);
  display: flex;
  align-items: center !important;
  border-radius: ${({ isSuccess }) => (isSuccess ? '100%' : '2px')} !important;
  justify-content: center !important;
  background-color: ${({ theme: { colors }, isSuccess }) =>
    isSuccess ? '#28a745' : colors.primary} !important;
  height: 40px;
  width: ${({ isSuccess }) => (isSuccess ? '40px' : '60px')} !important;
  color: #fff !important;
  border-color: transparent !important;
  transition: background-color 0.3s, opacity 0.3s;

  &:focus:not(.focus-visible) {
    box-shadow: none;
    outline: none;
  }
  &:focus-visible {
    box-shadow: 0 0 0 3px ${({ theme: { colors } }) => colors.secondary} !important;
    outline: none;
  }

  &:hover {
    opacity: 0.9;
  }
  svg {
    font-size: 20px;
  }
`;

export const LinkRecovery = styled.button`
  border: none;
  background-color: transparent;
  text-decoration: underline;
  color: ${({ theme: { colors } }) => colors.primary};
  margin-top: 10px;
  font-size: 0.9rem;

  &:hover {
    font-weight: 500;
  }
`;

export const StoreContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 15px;
  font-family: sans-serif;
  font-weight: 600;
  background-color: #f9f9f9;
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 10px 2px;
  gap: 10px;
`;

export const StoreText = styled.div`
  margin-top: 2px;
`;

export const ModalHeader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  font-size: 15px;
  font-family: sans-serif;
  font-weight: 600;
`;
